import React, { useEffect, useState } from "react";
import { Typography, Switch, notification, Space, Popconfirm } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, TIME_DURATION } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalPrAction from "./ModalPrAction";

import TableMui from "../../../../layout/TableMui";

const DichVuCC = ({
    dvName,
    dvId,
    isView,
    QuickSearchToolbar,
    isDichVuKhac,
    reloadNew,
}) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [reload, setReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dvId, reload, reloadNew]);
    const getData = () => {
        let data = {
            nccThongTinChungId: dvId,
            isDichVuKhac: isDichVuKhac,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvLandTour/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    if (Array.isArray(data) && data.length > 0) {
                        //let sttCounter = 1; // Khởi tạo STT từ 1

                        const transformedData = [];

                        data.forEach((item) => {
                            if (
                                !Array.isArray(
                                    item.tranxDvLandTourTimeRequests
                                ) ||
                                item.tranxDvLandTourTimeRequests.length === 0
                            ) {
                                return; // Nếu không có dữ liệu, bỏ qua item này
                            }
                            item.tranxDvLandTourTimeRequests.forEach((tour) => {
                                const valueToiDa =
                                    tour.tranxKhoangKhachLandTourRequests.map(
                                        (item) => item.soKhachToiDa ?? 0
                                    );
                                const valueToiThieu =
                                    tour.tranxKhoangKhachLandTourRequests.map(
                                        (item) => item.soKhachToiThieu ?? 0
                                    );
                                const soKhachTD =
                                    valueToiDa.length > 0
                                        ? Math.max(...valueToiDa)
                                        : 0;
                                const soKhachTT =
                                    valueToiThieu.length > 0
                                        ? Math.min(...valueToiThieu)
                                        : 0;
                                transformedData.push({
                                    stt: transformedData.length + 1, // STT tăng liên tục từ 1, 2, 3, ...
                                    id: 1 + Math.random() * (100 - 1),
                                    idLT: item.id,
                                    rowKey: 1 + Math.random() * (100 - 1),
                                    maDichVu: item.maDichVu,
                                    tenDichVu: item.tenDichVu,
                                    soNgay: `${item.soNgay}n/ ${item.soDem}đ`,
                                    thoiGianApDung: `${tour.tuNgay} - ${tour.denNgay}`,
                                    soKhachToiDa:
                                        soKhachTD > soKhachTT
                                            ? soKhachTD
                                            : soKhachTT,
                                    soKhachToiThieu: soKhachTT,
                                    loaiTienTe: item.maNgoaiTe,
                                    trangThai: tour.hieuLuc || false,
                                    hieuLuc: tour.hieuLuc || false,
                                });
                            });
                        });
                        setDataSource(transformedData);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    // const handleChange = (pagination, filters, sorter) => {
    //     console.log("Various parameters", pagination, filters, sorter);
    //     setFilteredInfo(filters);
    // };
    const handleBorderChange = (record, value) => {
        let values = {
            id: record.id,
            trangThaiBooking: value !== true,
            hieuLuc: record.hieuLuc,
        };
        console.log("handleBorderChange " + JSON.stringify(values));
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvLandTour/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        // setDataSource(newData);
    };
    // const viewRecord = (record) => {
    //     setEditingRecord(record);
    //     setIsShow(true);
    //     setAction(false);
    // };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.id !== record.id);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc !== true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvLandTour/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = async (status, values) => {
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
            isDichVuKhac: isDichVuKhac,
        };
        // console.log("data edit dv oto=====", data);
        try {
            let response = await axios({
                method: "post",
                url: API_BASE_URL + "ncc/dvLandTour/saveData",
                data: data,
                headers: authHeader(),
            });
            if (response.status === 200 && response.data.code === "00") {
                setIsShow(false);
                setAction(status);
                getData();
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
                return true;
            } else {
                setAction(true);
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: TIME_DURATION,
                });
                return false;
            }
        } catch (error) {
            console.log("Error response: ====" + error);
            api["error"]({
                message: t("stour"),
                description: t("edit") + " " + t("noti.error").toLowerCase(),
                duration: TIME_DURATION,
            });
            return false;
        }
        // axios({
        //     method: "post",
        //     url: API_BASE_URL + "ncc/dvOto/saveData",
        //     data: data,
        //     headers: authHeader(),
        // })
        //     .then(function (response) {
        //         console.log("response=====", response);
        //     })
        //     .catch(function (response) {
        //         console.log("Error response: ====" + response);
        //         api["error"]({
        //             message: t("stour"),
        //             description: t("edit") + " " + t("noti.error").toLowerCase(),
        //             duration: TIME_DURATION,
        //         });
        //     });
    };

    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
            // renderCell: (params) =>
            //     params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("soNgay"),
            field: "soNgay",
            key: "soNgay",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("tour.soKhachToiThieu"),
            field: "soKhachToiThieu",
            key: "soKhachToiThieu",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value }) => {
                if (value == null || value === "") return ""; // Nếu không có giá trị, hiển thị "0"
                return new Intl.NumberFormat("en-US").format(value); // Định dạng số: 2,000,000
            },
        },
        {
            headerName: t("tour.soKhachToiDa"),
            field: "soKhachToiDa",
            key: "soKhachToiDa",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value }) => {
                if (value == null || value === "") return ""; // Nếu không có giá trị, hiển thị "0"
                return new Intl.NumberFormat("en-US").format(value); // Định dạng số: 2,000,000
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTienTe",
            key: "loaiTienTe",
            flex: 1,
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            headerName: t("status"),
            field: "trangThai",
            key: "trangThai",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value, row }) => {
                if (value !== undefined) {
                    return (
                        <Switch
                            defaultChecked={value}
                            onChange={() => handleBorderChange(row, value)}
                        />
                    );
                } else {
                    return null;
                }
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        {/*<Typography.Link>*/}
                        {/*    <EyeOutlined onClick={() => viewRecord(record.row)} />*/}
                        {/*</Typography.Link>*/}
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record.row)}
                        >
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText("");
    // };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    return (
        <div>
            {contextHolder}

            <TableMui
                dataSource={dataSource}
                columns={columns}
                edit={edit}
                QuickSearchToolbar={QuickSearchToolbar}
            />
            {isShow && (
                <ModalPrAction
                    isOpen={isShow}
                    handleCancel={handleCancel}
                    handleFinish={handleFinish}
                    record={editingRecord}
                    action={action}
                    dvId={editingRecord.id}
                />
            )}
        </div>
    );
};
export default DichVuCC;
